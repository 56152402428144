import React, {useEffect, useState} from 'react'
import {graphql, Link, navigate, StaticQuery} from 'gatsby'
import Layout from '../../containers/layout'
import GraphQLErrorList from '../../components/graphql-error-list'
import Carousel from '../../components/carousel'
import PortableText from '../../components/portableText'
import {Formik} from 'formik';
import * as Yup from 'yup';
import {fetchDatafromAPI} from '../../lib/apiHelper'
import ACTIONS from '../../services/actions'
import {isLoggedIn, setUser} from '../../services/auth'
import userGroups from '../../services/userGroups'
import SEO from '../../components/seo'
import {toPlainText} from '../../lib/helpers'

import { useSelector, useDispatch } from "react-redux"

// import OktaSignInWidget from '../../components/OktaSignInWidget'
// import { useOktaAuth } from '@okta/okta-react'


export const query = graphql`
    query LoginPageQuery {
        pageInfo: sanityLoginPage(_id: {regex: "/(drafts.|)loginPage/"}) {
            _id
            carousel {
              banner {
                asset {
                  url
                }
              }
              _rawText(resolveReferences: {maxDepth: 5})
            }
            _rawBody(resolveReferences: {maxDepth: 5})
            title
        }

        site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
            title
            description
            keywords
        }
    }
`


const LoginPage = props => {
    const dispatch = useDispatch()

    const login = () => {
        // window.location.href = 'https://0kcwm7617e.execute-api.us-east-1.amazonaws.com/stage/auth/saml/login'
        // window.location.href = 'http://localhost:9000/.netlify/functions/auth/saml/login' // okta embed url(saml)
        // window.location.href = 'https://dev-03213077.okta.com/home/dev-03213077_dwbkfrontendstaging_1/0oap679apv6D0Vq8h5d6/alnp6hg980YGowyWo5d6'

        // window.location.href = 'https://rbistage.oktapreview.com/home/rbistage_dwbkfrontendstaging_1/0oazaevt1e6ZaeWAQ0h7/alnzafexbn3G0Jt2R0h7'
        window.location.href = process.env.OKTA_APP_ENDPOINT
    }

    // console.log('----.env: ', process.env.NATIVE_USER_API_ENDPOINT)

    const {data, errors, config} = props
    // console.log('-----------> data: ', data, config)

    if (errors) {
        return (
        <Layout>
            <GraphQLErrorList errors={errors} />
        </Layout>
        )
    }

    const pageInfo = (data || {}).pageInfo
    if (!pageInfo) {
        throw new Error(
        'Missing page info.'
        )
    }

    const site = (data || {}).site


    return(
        <Layout>
            <SEO title={pageInfo.title || 'Login'} description={toPlainText(pageInfo._rawBody) || site.description} />

            <div className='container container-adjust-p'>
            {pageInfo && <Carousel carousel={pageInfo.carousel} />}
            </div>

            {/* content */}
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-8" style={{margin: '0 auto'}}>
                        {pageInfo._rawBody && <PortableText blocks={pageInfo._rawBody} />}
                    </div>
                    
                    <div className="col-md-6 mb-8" style={{margin: '0 auto'}}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={ValidateSchema}
                            onSubmit={async (values, actions) => {

                                // add loginDuration: in (number)hours unit
                                values.loginDuration = 24

                                // if pass checking call lambda api
                                try {
                                    // let apiEndpoint = 'https://j43jfzh1qh.execute-api.us-east-1.amazonaws.com/prod/api'
                                    let apiEndpoint = process.env.NATIVE_USER_API_ENDPOINT
                                    let data = {
                                        action: ACTIONS.LOGIN,
                                        values: values
                                    }

                                    let response = await fetchDatafromAPI(apiEndpoint, data)
                                    // console.log('---> response: ', response)

                                    if(response.body && response.body.isLoggedIn && response.body.isLoggedIn === true){
                                        // unlock page process of Gatsby
                                        let userInfo = response.body.user ? response.body.user : {}
                                        userInfo.rbiUserType = userGroups.Franchisee  // for display admin link only
                                        setUser(userInfo)

                                        //-------set user login
                                        dispatch({ type: 'SET_USER', payload: userInfo })

                                        // redirect to index page
                                        navigate(`/`)

                                        // reset form after success
                                        actions.resetForm()
                                    }
                                    else{
                                        // actions.setErrors({failMessage: response.body.message})
                                        let message = "No user found for this email address. Click on the Access Request link below to request an account. If you have an account on OKTA, please login to your OKTA account and click on the DWBK link to access the site."
                                        actions.setErrors({failMessage: message})
                                    }

                                } catch (error) {
                                    console.log("log: create user -> error", error)
                                    actions.setErrors({failMessage: "Form submitting fail, Please try again or contact administrator."})
                                }

                            }}
                        >

                            {({ values, errors, touched, handleSubmit, handleChange, handleBlur, isSubmitting }) => (
                                <form 
                                    onSubmit={(e) => {
                                        e.preventDefault() // prevent refresh page
                                        handleSubmit()
                                    }}
                                >
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="examplename">Email</label>
                                        <input name="email" type="text" className="form-control" id="email" placeholder="Email" onChange={handleChange} onBlur={handleBlur} value={values.email} />
                                        {errors.email && touched.email ? <div className="alert alert-danger mt-1 pt-2 pb-2" role="alert">{errors.email}</div> : null}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="password">Password</label>
                                        <input name="password" type="password" className="form-control" id="password" placeholder="Password" onChange={handleChange} onBlur={handleBlur} value={values.password} />
                                        {errors.password && touched.password ? <div className="alert alert-danger mt-1 pt-2 pb-2" role="alert">{errors.password}</div> : null}
                                    </div>

                                    {
                                        isSubmitting ? 
                                        <button type="submit" className="btn btn-secondary col-12" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Submitting...
                                        </button>
                                        : <button type="submit" className="btn btn-primary col-12">Sign In</button>
                                    }

                                    {errors.failMessage && 
                                        <div className="alert alert-danger" role="alert">
                                            <h4 className="alert-heading mb-1">Fail!</h4>
                                            <hr className="mt-1" />
                                            <p>{errors.failMessage}</p>
                                        </div>
                                    }

                                </form>
                            )}

                        </Formik>

                        <hr/>
                        <button id="login-button" onClick={login} className="btn col-12 btn-blue">Sign In with Okta</button>


                        <div className="mt-3">
                            <div>
                                Need an account? <Link to='/auth/request-access' >Request access</Link>
                            </div>
                            <div>
                                Forgot your password? <Link to='/auth/reset-password' >Reset your password</Link>
                            </div>                                
                        </div>

                        

                    </div>  
                </div>                
            </div>
            

        </Layout>
    )
}

const initialValues = {
    email: '',
    password: ''
}

const ValidateSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format.').required('Email is required.'),
    password: Yup.string()
        .required('Password is required.')
        .min(7, "The password must be 7 or more characters and have one each of: upper-case letter, lower-case letter, number and symbol.")
        .matches(
            new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{7,})"),
            "The password must be 7 or more characters and have one each of: upper-case letter, lower-case letter, number and symbol."
        ),
});

export default LoginPage